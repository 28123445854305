.Navbar {
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding:  25px 2% 10px 2%;
    position: absolute;
    top: 0;
    z-index: 10;
}

.NavbarPlain{
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding:  25px 2% 10px 2%;
    position: absolute;
    top: 0;
    z-index: 10;
}

.title_logo{
    width: 20%;
    max-width: 400px;
    margin-top: 1%;
    img{
        width:100%;
    }
}

.title_logo_min {
    width: 15%;
    max-width: 270px;
    img{
        width:100%;
    }
}

.navbar_right_part{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.navbar_menu{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25px;
}

.navbar_item{
    margin-left:20px;
    a{
        text-decoration:none; 
        font-weight:600;
        letter-spacing: 1px;
        color:rgb(230, 230, 230);
        margin: 10px 4px;
    }
}

.navbar_item:hover{
    a{
        color:white;
        transition: all .2s ease-in-out;

    }
}

.social_nav{
    display: flex;
    margin: -9px 10px 0 0;
    z-index: 2;
}

.link{
    display:flex;
    justify-content: center;
    align-items: center;
    border: solid 1.5px rgba(230, 230, 230, 0.287);
    width: 40px;
    height: 40px;
    margin: 0px 8px;
    transition: all .3s ease-in-out;
}

.link:hover{
    background-color: rgba(255, 255, 255, 0.281);
}

.link a img{
    width:100%;
}

.button_connect{
    cursor: pointer;
    background-color: #eeeeee;
    color: #1f2f4d;   
    border: none;
    border-radius: 28px;
    height: 39px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    padding: 5px 14px;
    margin-top: 3px;
}

.button_connect:hover{
    background-color: #dbdbdb;
}

.button_connect_mobile{
    cursor: pointer;
    background-color: #eeeeee;
    color: #1f2f4d;   
    border: none;
    border-radius: 28px;
    min-width:80px;
    height: 40px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    padding: 5px 14px;
    margin-top: 30px;
    .t12{
        width: 100% !important;
        background:none !important;
    }
}

.button_connect_mobile:hover{
    background-color: #dbdbdb;
}

.button_navbar {
    font-family: 'neon_tubes_2';
    font-size: 1em;
    font-weight: normal;
    border:none;
    border-radius: 10px;
    background: white;
    padding: 20px;
    margin: 5px;
    width: 210px;
    transition: all 0.5s;
    cursor: pointer;
    letter-spacing: 1px;
}

.burger_menu {
    display:none;
}

@media screen and (max-width: 1400px) {
    .navbar_item{
        a{
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 900px) {
    .navbar{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title_logo{
        width: 30%;
    }

    .title_logo_min {
        width: 30%;
    }

    .button_navbar{
        width: 100%;
        font-size: 1em;
        padding:10px;
    }

    .navbar_right_part{
        display:none;
    }

    .burger_menu {
        display:block;
    }

}

#menuToggle
{
  display: block;
  position: relative;
  z-index: 1;
}

#menuToggle input
{
  display: block;
  width: 33px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

/*
 Apprance du menu burger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  margin-right: 7px;
  position: relative;
  background: white;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

/*
 Apprance de la croix
 */
#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
    Position de la page blanche
 */
#menu{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    margin: -52px -96vw;
    background: #1a1a2049;
    backdrop-filter: blur(6px);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.burger_logo{
    width: 50%;
    margin-top: 30px;
    margin-bottom: 150px;
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}

li{
    a{
        text-decoration:none; 
        font-size: 25px;
        font-weight:500;
        letter-spacing: 3px;
        color:white;
        margin-top: 10px;
        transition: all 0.3s;
    }
}