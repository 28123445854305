html{
    background-color: #171618;
    scroll-behavior: smooth;
}

img{
    pointer-events: none;
}

@font-face {
    font-family: 'space_grotesk';
    src: url('assets/spacegrotesk-regular.woff2') format('woff2'),
         url('assets/spacegrotesk-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'anton';
    src: url('assets/anton.woff2') format('woff2'),
         url('assets/anton.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'teko';
    src: url('assets/teko.woff2') format('woff2'),
         url('assets/teko.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'rajdhani';
    src: url('assets/rajdhani.woff2') format('woff2'),
         url('assets/rajdhani.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
      

h1, h2, h3, h4{
    color: white;
    text-align:center;
}

h1{
    padding-bottom: 10px;
}

h2, h3{
    margin:0px;
}

p{
    color:rgb(196, 196, 196);
    font-family: 'space_grotesk';
}

::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-track{
    background:#131313;
}

::-webkit-scrollbar-thumb{
    background: white;
}

::-webkit-scrollbar-thumb:hover{
    background: rgb(204, 204, 204);
}



