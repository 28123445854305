.MyGHORSE{
    margin-bottom: 220px;
    padding-bottom: 300px;
    box-shadow: 0px 10px 10px #0b0d0f;
    width: 100%;
    background-color: #171618;
    cursor: default;
}




/*--------------------------------- PROFIL PART ----------------------------------*/

.Profil{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-bottom:220px;

}

.ProfilBanner{
    top: 0;
    width: 100%;
    height: fit-content;
    opacity: .5!important;
    filter: blur(4px);
    
    img{
        width: 100%;
        -webkit-mask-image:-webkit-gradient(linear,  80%,  bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    }
}

.ProfilMobileBanner{
    display: none;
}

.ProfilHeader{
    background-color: #171618;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(14, 16, 20);
    box-sizing: border-box;
    cursor: pointer;
    span{
        height: 40px;
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: #858089;
        // color: #9090a7;
        color: #787891;
        transition: color .3s ease-in-out;
        color: 0.4s ease 0s;
        font-size: 18px;
    }
    span:hover{
        color: white;
    }   
}

.ProfilHeaderMobile{
    display: none;
}
.ProfilCtn{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #171618;
}

.ProfilCtnHead{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    span{
        display: flex;
        background-color: #25252D;
        height: 45.7px;
        border-radius: 7px;
        margin: 0 20px;
    }
    span:hover{
        background-color: #2d2d35;

    }
    .format-1{
        width: 100%;
        cursor: text;
        div{
            box-sizing: border-box;
            padding: 10px;
            color: rgb(169, 188, 204);
        }
        input{
            border: none;
            font-size: 15px;
            background: none;
            color: white;
            width: 100%;

        }
        input:focus{
            outline: none;
        }
    }
 
}
.ProfilCtnHead-reload{
    width: 60px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.863);
    box-sizing: border-box;
    padding: 5px 16px;
    border: 2px solid #bb73308a
}
.ProfilCtnHead-reload:hover{
    background-color: rgba(255, 255, 255, 0.096);
    color: white;
}


.ProfilCtnHead-format{
    display: flex;
    cursor: pointer;
    div{
        box-sizing: border-box;
        border: solid 1.5px rgba(230, 230, 230, 0.287);
        color: rgba(255, 255, 255, 0.76);
        width: 50px;
        height: 50px;
        padding: 12px;
    }
}

.filter{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        margin: 0;
    }
}
.filterActive{
    background-color: rgba(255, 255, 255, 0.096);
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        margin: 0;
    }
}
.formatActive{
    background-color: rgba(255, 255, 255, 0.096);
}

.ProfilItems{
    h2{
        text-align: center;
        font-size: 32px;
        margin-top: 120px;
    }
}

.ProfilRewards{
    box-sizing: border-box;
    padding: 40px;
}

.ProfilRewards-Head{
    border: 2px solid #0f0f16;
    padding: 15px;
    background-color: #1a1a20;
    display: flex;
    svg{
        font-size: 26px;
        color: white;
    }
    h3{
        font-size: 20px;
        text-align: start;
    }
}
.ProfilRewards-Ctn{
    padding: 10px 18px;
    margin: 10px 0;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    background-color: #1a1a20;

    div{
        display: flex; 
        align-items: center;
        justify-content: space-between;
    }
    button{
        cursor: pointer;
        border: 2px solid #e741a2;
        border-radius: 4px;
        color: #e74165;
        background: none;
        font-size: 10px;
        height: 24px;
        
        width: 80px;
        font-weight: 600;
        padding: 5px 8px;
    }
    
    button:hover{
        background-color: #b82525;
        color:white;
        border-color: #b82525;

    }
}

.ProfilRewards-Foot{
    margin-top: 100px;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #0f0f16;

    button{
        cursor: pointer;
        background-color: #b82525;
        color: white;
        border: none;
        width: 80px;
        height: 24px;
        font-size: 10px;
        font-weight: 600;
        border-radius: 3px;
        padding: 5px 8px;
    }
    
    button:hover{
        background-color: #8f1515;
    }
}

.ProfilSales{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #171618;
    box-sizing: border-box;
    padding: 10px 36px;
}

.ProfilSalesHead{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1.5px solid #080a0e;
    box-sizing: border-box;
    padding: 15px 3%;
    p{
      color: white;
      font-size: 15px;
      font-weight: bold;
    }
}


@media screen and (max-width: 1100px) {

    .MyGHORSE{
        margin-bottom: 230px;
    }
    .ProfilHeader{
        padding-top: 10px;
        span{
            font-size: 14px;
            flex-direction: column;
        }
    }
    .ProfilBanner{
        display: none;
    }
    
    .ProfilHeader{
        display: none;
    }

    .ProfilHeaderMobile{
        background-color: #171618;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid rgb(14, 16, 20);
        padding-top: 15px;
        box-sizing: border-box;
        cursor: pointer;
        span{
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // color: #858089;
            // color: #9090a7;
            color: #787891;
            transition: color .3s ease-in-out;
            color: 0.4s ease 0s;
            font-size: 16px;
            svg{
                margin-bottom: 5px;
            }
            div{
                height: 20px;
                padding-bottom: 7px;
            }
        }
        span:hover{
            color: white;
        }   
    }
    
    .ProfilMobileBanner{
        display: block;
        top: 0;
        width: 100%;
        height: fit-content;
        opacity: .6!important;
        filter: blur(2px);
        
        img{
            width: 100%;
            -webkit-mask-image:-webkit-gradient(linear,  10%,  bottom, from(rgba(0, 0, 0, 0.116)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0, 0, 0, 0.02));
        }
    }
    .ProfilItems{
        h2{
            text-align: center;
            font-size: 26px;
            margin-top: 60px;
        }
    }
    .ProfilSalesHead{
        display: none;
    }
    .ProfilRewards{
       padding: 5vw;
    }
}

@media screen and (max-width: 800px) {

    .MyGHORSE{
        margin-bottom: 250px;
    }
}