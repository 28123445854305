h1{
    margin: 0px;   
   }

img{
    width : 60%;
    border-radius: 10px;
}

.section_about{
    background-color: #03050f;
    padding-left:15%;
    padding-right:15%;
    padding-top:60px;
    padding-bottom:60px;
}

.section_mint{
    display: flex;
    justify-content: space-around;
    background-color: #0f1013;
    height: 600px;
    padding-left:10%;
    padding-right:10%;
}

.section_roadmap{
    background-color: #03050f;
    padding-left:10%;
    padding-right:10%;
    padding-top:60px;
    padding-bottom: 60px;
}

.section_phases_roadmap{
    background-color: #0f1013;
    padding-left:15%;
    padding-right:15%;
    padding-top:60px;
    padding-bottom: 60px;
}

.section_faq{
    background-color: #03050f;
    padding-left:15%;
    padding-right:15%;
    padding-top:60px;
    padding-bottom: 60px;
}

.section_rarities{
    background-color: #03050f;
    padding-left:15%;
    padding-right:15%;
    padding-top:60px;
    padding-bottom: 60px;
}

.section_team{
    background-color: #0f1013;
    padding-left:15%;
    padding-right:15%;
    padding-top:60px;
    padding-bottom: 60px;
}

.section_community{
    display: flex;
    justify-content: space-between;
    background-color: #0f1013;
    padding-left:5%;
    padding-right:5%;
    padding-top: 20px;
    margin-bottom:220px;
    box-shadow: 0px 10px 10px #0b0d0f;
}

.section_footer{
    position: fixed;
    bottom:0;
    z-index: -1;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1a20;
    width: 100%;
    height: 220px;
}

.loader_screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #03050f;
    width: 100%;
    height: 100vh;
    z-index: 90;
}

.loader_logo_img{
    width:200px;
}

@media screen and (max-width: 1500px) {
    .section_mint{
        height: 500px;
    }
}

@media screen and (max-width: 1300px) {
    .section_mint{
        height: 400px;
    }
}

@media screen and (max-width: 1100px) {

     .section_about,
     .section_roadmap,
     .section_phases_roadmap,
     .section_faq,
     .section_rarities,
     .section_team,
     .section_community{
        padding-left:10%;
        padding-right:10%;
    }

    .section_mint{
        height: 350px;
        padding-left:5%;
        padding-right:5%;
    }

    .section_community{
        justify-content: center;
        margin-bottom:250px;
    }

    .section_footer{
        padding-top: 20px;
        height: 230px;
    }
}

@media screen and (max-width: 700px) {

    .section_about,
    .section_mint,
    .section_roadmap,
    .section_phases_roadmap,
    .section_faq,
    .section_rarities,
    .section_team,
    .section_community{
       padding-left:5%;
       padding-right:5%;
   }
}

.not_found{
    display: flex;
    flex-direction: column;
    align-items: center;
    height:50vh;
    font-family: 'space_grotesk';
    margin-top: 5%;
}

.not_found img{
    height: inter-word;
    width: 50%;
    max-width: 400px;
    margin-top: 20px;
}

@media screen and (max-width: 900px) {

    .section_header{
        height: 100vh;
    }

}

/*--------------------------- HEADER PART --------------------------------- */
.section_header{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: #060606;
    background: url("../assets/Background_Horsemen.png") no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
}

.clothing_rack{
  //background-color: red;
  width:7%;
  height: 60%;
  position: absolute;
  top: 58%;
  right: 23%;
  transform: translate(-50%, -50%);  
  margin: 0;
}

.header_content{
    display: flex;
    justify-content: center;
    height: 100%;
    width:90%;
}
  
/* SCROLL THINGS */
.scroll-down {
    height: 50px;
    width: 30px;
    border: 2px solid rgba(230, 230, 230, 0.753);
    position: absolute;
    left: 50%;
    bottom: 20px;
    border-radius: 50px;
    cursor: pointer;
  }
  .scroll-down::before,
  .scroll-down::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border: 2px solid rgba(230, 230, 230, 0.979);
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1s ease-in-out infinite;
  }
  .scroll-down::before {
    top: 30%;
    animation-delay: 0.3s;
    /* animation: scroll-down 1s ease-in-out infinite; */
  }
  
  @keyframes scroll-down {
    0% {
      /* top:20%; */
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      top: 90%;
      opacity: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    .scroll-down {
        height: 40px;
        width: 25px;
    }
    .scroll-down::after {
        height: 7px;
        width: 7px;
    }
}

@media screen and (max-width: 1000px) {
    .clothing_rack{
        display: none;
      }
}
/*----------------------------------- ABOUT PART ---------------------------------- */

.about_welcome{
    display:flex;
    flex-direction: column;
    border-radius:20px;
    p{
      font-size: 20px;
      text-align:justify;
    }
}

.swiper{
    width: 100%;
    height: 100%;
}

/* SWIPER ABOUT */
.swiper_about{
    width: 100%;
    height: 100%;
}
  
.swiper_about_responsive{
    display: none;
}
  
.swiper_slide_about{
    height: 100%;
    width:100%;
}
    
.swiper_img{
    width:100%;
    border-radius: 10px;
}
    
@media screen and (max-width: 800px) {
  
    .swiper_about{
        display: none;
    }
  
    .swiper_about_responsive{
        display: block;
    }
  
}

/*----------------------------------- MINT PART ---------------------------------- */


.mint_left{
    display: flex;
    flex-direction: column;
    position: relative;
    height:100%;
}

.mannequin_light{
    position: relative;
      top: 0;
      left: 0;
    border-radius: 0px;
    z-index:1;
    height:100%;
    width: 100%;
}

.mint_gif{
    position: absolute;
    height: 90%;
    width: auto;
      top: 60px;
      left: 40px;
}

.mint_right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
}

.mint_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
        font-size: 20px;
        text-align: center;
      }
    h2{
        font-family: 'Anton';
        color:rgb(196, 196, 196);
        font-size: 4.3em;
        letter-spacing: 5px;
    }
}

.mint_ghorse_list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.ghorse_list_item{
    width: 11px;
    height: 11px;
    border-radius: 100px;
    background-color:rgb(47, 47, 47);  
    margin-left: 12px;
}

.ghorse_list_itemFilled{
    width: fit-content;
    height: fit-content; 
}

.mint_ghorse_head{
    width:23px; 
    height:23px; 
    margin: 0 -3px -0px 3px;
    display: block;
}

.mint_ghorse_head-none{
    display: none;
}
.button_mint{
    cursor: pointer;
    background-color: #1f2f4d;
    color: white;
    border: none;
    border-radius: 28px;
    width: 100%;
    height: 39px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 8px;
    margin-top: 3px;
}

.button_mint:hover{
    background-color: #142038;
}

.mintCount{
    display: flex; 
    justify-content: space-between; 
    align-items:center;
    border: 1.6px solid #424242a2 ;
    border-radius: 4px;
    padding: 4px;
    button{
        width: 40%;
        cursor: pointer;
        height: 60px;
        background: none;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 28px;
        background-color: #4b4b4b5e;
    }
    button:hover{
        background-color: #3838385e;
    }
    p{
        margin: 0;
    }

}

@media screen and (max-width: 1500px) {
    .mint_gif{
      top: 50px;
      left: 30px;
    }
}

@media screen and (max-width: 1335px) {
    .mint_container{
        p{
            font-size: 17.5px;
          }
        h2{
            font-size: 3.6em;
        }
    }
}

@media screen and (max-width: 1300px) {
    .mint_gif{
      top: 40px;
      left: 30px;
    }
}

@media screen and (max-width: 1100px) {
    .mint_gif{
      top: 35px;
      left: 20px;
    }
}

@media screen and (max-width: 950px) {
  
    .mint_left{
        display: none;
    }

    .mint_right{
        margin-left: 0px;
    }
  
}


/*----------------------------------- ROADMAP PART ---------------------------------- */

.roadmap_line_sold{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
    
.roadmap_card_sold{
    display: flex;
    flex-direction: column;
    width: 250px;
    min-height:250px;
    border: solid rgb(112, 112, 112) 1px;
    border-radius: 5px;
    margin: 20px;
    padding: 20px;
    h1{
        text-align: start;
    }
    p{
        text-align: justify;
    }
}
    
.roadmap_title_sold{
    display: flex;
    align-items: flex-start;
    color: white;
    h1{
        font-size: 4.3em;
        font-family: 'teko';
        span{
            font-size: 1.0em; 
        }
    }
    span{
        margin-left: 5px;
        margin-top: 38px;
        font-size: medium;
        font-weight: 500;
    }
} 

/*----------------------------------- PhasesRoadmap ----------------------------------*/
    
.roadmap_line_phase{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
    
.roadmap_card_phase{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 20px 0px;
}
    
.roadmap_title_phase{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    color:white;
    h1{
        font-family: 'teko';
        font-size: 100px;
        line-height: 60px;
        margin-top: 5px;
    }
    span{
        font-size: small;
        text-align: center;
        font-size: 25px;
    }
}

.phase_1{
    span, h1{
        color:#6dc729
    }
}

.phase_1_container{
    border:solid 1px #6dc729 !important;
}
    
.roadmap_container_phase{
    width: 80%;
    border: solid rgb(112, 112, 112) 1px;
    border-radius: 5px;
    padding: 20px;
    text-align: justify;
    p{
        margin-bottom:0px
    }
}

@media screen and (max-width: 800px) {

   .roadmap_card_phase{
       flex-direction: column;
   }

}

/*----------------------------------- FAQ PART ---------------------------------- */

.MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  
  .MuiAccordion-rounded {
    border-radius: 10 !important;
  }
  
  div#panel2bh-header {
    border-radius: 10px;
  }
  
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background-color: #131313 !important;
    border-radius: 10px !important;
    margin-bottom:10px !important;
    padding :5px !important;
    text-align:justify !important;
  }
  
  .css-67l5gl {
    background-color: #131313 !important;
    border-radius: 10px !important;
    margin-bottom:10px !important;
    padding :5px !important;
  }
  
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before{
    background-color: #060606 !important;
  }
  
  .section_faq_aos{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faq_title{
    margin-bottom: 20px;
  }
  
  .faq_liste{
    width: 100%;
  }

    /*----------------------------------- RARITY PART ---------------------------------- */

    .section_rarities_aos{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .rarities_container{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .rarities_left{
        display: flex;
        flex-direction: column;
        width: 50%;
        p{
            font-size: 20px;
            text-align: justify;
          }
    }
    
    .rarities_liste{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
      
    .rarities_common,
    .rarities_uncommon,
    .rarities_rare,
    .rarities_epic,
    .rarities_exotic{
        h2{
            color:white;
            font-size: 20px;
        }
        span{
            color:white;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 7px;
        width: 180px;
        padding: 5px;
        border-radius: 5px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    
    .rarities_common{
        background-image: linear-gradient( 94.3deg,  #1d7617 10.9%, #116639 87.1% );
    }
    
    .rarities_uncommon{
        background-image: radial-gradient( circle farthest-corner at 10% 20%,  #324cbd 0%, #4821b1 90% );
    }
    
    .rarities_rare{
        background-image: linear-gradient( 109.6deg,  #601b95 11.2%, #7c1975 98.6% );
    }
    
    .rarities_epic{
        background-image: radial-gradient( circle farthest-corner at 10% 20%,  #a80707 0%, #c62402 90% );
    }
    
    .rarities_exotic{
        background: linear-gradient(to right, #b88e0e, #e6ce05);
    }
    
    .button_traits {
        font-size: 1em;
        font-weight: normal;
        border-radius: 10px;
        background: none;
        color:white;
        padding: 20px;
        margin: 5px;
        width: 210px;
        transition: all 0.5s;
        cursor: pointer;
        letter-spacing: 1px;
    }
    
    .rarities_right{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    
    .pyramid_img{
        width: 90%;
    }

    @media screen and (max-width: 1200px) {
      
        .rarities_container{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .rarities_left{
            width: 100%;
        }

        .rarities_right{
            margin-top: 40px;
            width: 100%;
        }
        
        .pyramid_img{
            width: 60%;
        }
    }

    @media screen and (max-width: 520px) {

        .rarities_common,
        .rarities_uncommon,
        .rarities_rare,
        .rarities_epic,
        .rarities_exotic{
            width: 125px;
        }

        .pyramid_img{
            width: 100%;
        }
    }

/*----------------------------------- TEAM PART ---------------------------------- */

.section_team_aos{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team_liste{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.team_line{
    display: flex;
    justify-content: center;
}

.team_card{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin : 10px;
    position: relative;
}

 .team_img{
    width:100%;
}

  .team_name{
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      left: 20px;
      h2{
          font-size: 1.5vw;
      }
  }
  
  @media screen and (max-width: 1300px) {
      
    .team_card{
        display:flex;
        flex-direction: row;
        justify-content:start;
        width:40%;
    }

    .team_name{
        h2{
            font-size: 2.5vw;
        }
    }

  }

/*----------------------------------- COMMUNITY PART ---------------------------------- */

.community_center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:50%;
    margin: 30px 0px 30px 0px;
    h1{
        font-family: 'anton';
        text-align: center;
        font-size: 2.8em;
        color: rgb(241, 241, 241);
        letter-spacing: 3px;
        padding-bottom:0px;
    }
    p{
        text-align: center;
        line-height: 25px;
        margin: 30px 0px 30px 0px;
    }
    a{
        width: 200px;
    }
}

.community_link{
    display: flex;
    justify-content: center;
}

.button_community{
    font-family: 'space_grotesk';
    font-size: 1em;
    border-radius: 5px;
    border: none;
    color:white;
    font-weight: 800;
    width: 200px;
    padding: 20px;
    transition: all 0.5s;
    /*cursor: pointer;*/
    letter-spacing: 1px;
}

.button_discord{
    background-color: #4753d6;
    cursor: pointer;
}

.button_discord:hover{
    background-color: #6976e6;  
}

.button_telegram{
    background-color: #2a9dd5;
    margin-left: 10px;
}

/*.button_telegram:hover{
    background-color: #48bbf5;  
}*/

.community_left{
    display: flex;
    flex-direction: row;
    width: 25%;
    img{
        width:100%
    }
}

.community_right{
    display: flex;
    flex-direction: row-reverse;
    width: 25%;
    img{
        width:100%
    }
}

@media screen and (max-width: 1100px) {

    .community_left,
    .community_right{
        display: none;
    }

    .community_center{
        width:90%;
    }

}

@media screen and (max-width: 500px) {

    .community_link{
        flex-direction: column;
        align-items: center;
    }

    .button_telegram{
        margin-top: 10px;
        margin-left: 0px;
    }
}
