.Footer{
    width: 100%;
}

.footer_content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'space_grotesk';
    width: 80%;
    margin: 50px;
    p{
        margin-top:5px;
        margin-bottom:0px;
    }
}

.footer_left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer_infos{
    display: flex;
    align-items: center;
}

.footer_right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer_nav{
    display: flex;
    flex-direction: column;
    a{
        text-decoration:none; 
        font-weight:500;
        letter-spacing: 3px;
        color:white;
        margin-top: 10px;
        transition: all 0.3s;
    }
    a:hover{
        text-shadow:
        0px 0px 1px #ffffff,
        0px 0px 1px rgba(214, 214, 214, 0.781),
        0px 0px 5px #ffffff;
    }
}

@media screen and (max-width: 1100px) {

    .footer_content{
        flex-direction: column-reverse ;
        margin: 50px 20px;
    }

    .footer_infos{
        margin-top: 20px;
        margin-bottom: 10px;
        img{
            width:300px;
        }
    }

    .footer_nav{
        display: none;
    }
}