.Market{
    margin-bottom: 220px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 10px 10px #0b0d0f;

    box-sizing: border-box;    
    background-color: #060606;
    background: url("../assets/farming_banner.png") no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    ::-webkit-scrollbar{
        width: 10px;
    }
    
    ::-webkit-scrollbar-track{
        background:#1a1a1aaf;
    }
    
    ::-webkit-scrollbar-thumb{
        background: #303438;
    }
    
    ::-webkit-scrollbar-thumb:hover{
        background: #383c41;
    }
    
}
/*--------------------------------- HEADER PART ----------------------------------*/
  
.MrkBanner{
    width: 100%;
    height: fit-content;
    opacity: .5!important;
    filter: blur(4px);
    
    img{
        width: 100%;
        -webkit-mask-image:-webkit-gradient(linear,  80%,  bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    }
}
.MrkBannerMobile{
    display: none;
}



.MrkInfoCtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    //background-color: #171618;
    position: absolute;
    top: 10vh;

    h1{
        // margin-top: -180px;
        // font-size: 120px;
        font-size: 5vw;
        font-family: Anton;
    }
    h4{
        margin-bottom: 10px;
        margin-right: 4.9vw;
        color: rgba(255, 255, 255, 0.774);
        // font-size: 120px;
        font-size: 0.7vw
    }
 
}

.MrkInfoCtn-2{
    display: none;
}
.MrkInfoPanel{
    border-radius: 8px;
    display: flex;
    border: solid 1.5px rgba(230, 230, 230, 0.287);
    div{
        display: flex;
    }
    span{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 35px 20px 35px;
        border-right:  solid 1.5px rgba(230, 230, 230, 0.287);
    }

    h2{
        font-size: 1.1vw;
    }
    p{
        margin: 2px 0 0 0;
        font-size: 0.54vw;
    }
}


@media screen and (max-width: 1100px) {

    .MrkBanner{
        display: none;
    }
    .MrkBannerMobile{
        display: block;
        top: 0;
        width: 100%;
        height: fit-content;
        opacity: .6!important;
        filter: blur(2px);
        
        img{
            width: 100%;
            -webkit-mask-image:-webkit-gradient(linear,  10%,  bottom, from(rgba(0, 0, 0, 0.116)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0, 0, 0, 0.02));
        }
    }

    .MrkInfoCtn{
        display: none;
    }
}

/*--------------------------------- CONTENT PART ----------------------------------*/

.MrkContent{
    width: 60%;
    height: 50vh;
    box-sizing: border-box;
    border:1.6px solid black;
    margin-top: 160px;
}

@media screen and (max-width: 1300px) {
    .MrkContent{
        margin-top: 90px;
        width: 70%;
        height: 70vh;
    }  
}

.MrkContentHeader{
    padding-top: 20px;
    background-color: #171618;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(14, 16, 20);
    box-sizing: border-box;
    cursor: pointer;
    span{
        height: 40px;
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: #858089;
        // color: #9090a7;
        color: #dfdfdf;
        transition: color .3s ease-in-out;
        color: 0.4s ease 0s;
        font-size: 18px;
    }
    
    span:hover{
        color: white;
    }
    
}
.MrkContentItems{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #171618;
}

.MrkContentItemsHead{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    span{
        display: flex;
        background-color: #25252D; 
        height: 45.7px;
        border-radius: 7px;
        margin: 0 20px;
    }
    span:hover{
        background-color: #2d2d35;

    }
    .format-1{
        width: 100%;
        cursor: text;
        div{
            box-sizing: border-box;
            padding: 10px;
            color: rgb(169, 188, 204);
        }
        input{
            border: none;
            font-size: 15px;
            background: none;
            color: white;
            width: 100%;

        }
        input:focus{
            outline: none;
        }
    }
 
    .format-2{
        box-sizing: border-box;
        padding: 0 5px 0 15px;
        cursor: pointer;
        input{
            border: none;
            font-size: 15px;
            background: none;
            cursor: pointer;

        }
        input:focus{
            outline: none;
        }
        div{
            box-sizing: border-box;
            padding: 10px;
            color: rgb(169, 188, 204);
        }
    }
    .format-2:active{
        background-color: #2d2d35;
    }
}
.contentFetched{
    border-bottom: 3px solid #946233
}

.MrkContentItemsSubHead-1{
    display:flex; 
    width: 45%;
}
.MrkContentItemsSubHead-2{
    display:flex; 
    justify-content: flex-end; 
    align-items: center; 
    width: 40%
}
.MrkContentItemsHead-reload{
    width: 60px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.863);
    box-sizing: border-box;
    padding: 5px 16px;
    border: 2px solid #bb73308a
}
.MrkContentItemsHead-reload:hover{
    background-color: rgba(255, 255, 255, 0.096);
    color: white;
}



.MrkContentItemsHead-format{
    display: flex;
    cursor: pointer;
    div{
        box-sizing: border-box;
        border: solid 1.5px rgba(230, 230, 230, 0.287);
        color: rgba(255, 255, 255, 0.76);
        width: 50px;
        height: 50px;
        padding: 12px;
    }
}

.formatActive{
    background-color: rgba(255, 255, 255, 0.096);
}
.Tippytoolbox{
    background-color: #2d2d35;
    display: flex;
    flex-direction: column;
    width: 200px;
    color: rgba(255, 255, 255, 0.877);
    border-radius: 8px;
    span{
        padding: 15px 0 15px 15px;
        box-sizing: border-box;
        border-bottom: 1.5px solid rgb(14, 16, 20);

    }
    span:hover{
        background-color: #25252D;
    }
}   

/* SALES PART ------------ */
.MrkContentSales{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #171618;
    box-sizing: border-box;
    padding: 10px 36px;
}

.MrkContentSalesHead{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1.5px solid #080a0e;
    box-sizing: border-box;
    padding: 15px 3%;
    p{
      color: white;
      font-size: 15px;
      font-weight: bold;
    }
}

.MrkContentItemsCnt{
    width: 65%;
    overflow-y: scroll;
}

.MrkContentItemsCnt2{
    width: 30%;
    padding-left: 20px;
    border-left: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.MrkButton{
    cursor: pointer;
    background-color: #1f2f4d;
    color: white;
    border: none;
    border-radius: 28px;
    width: 100%;
    height: 39px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 8px;
    margin-top: 3px;
    margin-bottom: 10px;

}

.MrkButton:hover{
    background-color: #142038;
}


/*--------------------------------- ITEMS PART ----------------------------------*/

.Items{
    margin-top: 30px;
    width: 100%;
    background-color: #25252D;
    border-radius: 9px;
    cursor: pointer;
    p{
        margin: 0;
    }
}

.ItemsSelected{
    margin-top: 30px;
    width: 100%;
    background-color: #25252D;
    border-radius: 9px;
    border: 3px solid green;
    cursor: pointer;
    p{
        margin: 0;
    }
}
.ItemsImg{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        height: 60px;
        width: 60px;
        color: #787891;

    }
}
.ItemsContent{
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 13px 13px 20px 13px;
  span{
    display: flex;
  }
  h4{
    margin: 3px 0 0 5px;
    font-size: 16px;
  }
  p{
    font-size: 12px;
  }
  b{
    color: white;
    font-weight: 500;
  }

}
.ItemsFooter{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 2px solid rgb(20, 22, 27);
    height: 50px;
    box-sizing: border-box;
    padding: 13px 13px;

    span{
        display: flex;
        color: rgba(255, 255, 255, 0.63);
        font-size: 13px;
        font-weight: 600;
        svg{
            margin-right: 5px;
            font-size: 20px;
        }
        svg:hover{
            color: red;
        }
    }
}


/*--------------------------------- SIDEBAR PART ----------------------------------*/


.MrkSidebar{
    width: 420px;
    background-color: #1a1a20;
    height: 100%;    

    
    p{
        color: rgba(255, 255, 255, 0.63);
        margin-left: 5px;
        font-weight: 500;
       }
    span{
        font-size: 16px;
        display: flex;
        align-items: center;
        p{
         color: rgba(255, 255, 255, 0.63);
         margin-left: 5px;
         font-weight: 500;
        }
    }

}

.MrkSidebar_hide{
    width: 60px;
    background-color: #1a1a20;
    height: 100%;
}


.MrkSidebarItem{
    width: 100%;    
    height: 70px;
    padding: 10px 16px;
    box-sizing: border-box;
    background-color: #1a1a20;
    border-bottom: 1.6px solid #0f0f16;
    cursor: pointer;

    p{
        color: rgba(255, 255, 255, 0.63);
        margin-left: 5px;
        font-weight: 500;
       }
    div{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span{
            display: flex;
        }
    }
}
.MrkSidebarItem:hover{
    svg{
        color: rgba(255, 255, 255, 0.63);
    }
}

.MrkSidebarSubItem{
    width: 100%;    
    background-color: #25252D;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    height: 15vh;
    div{
        cursor: pointer;
    }
    p{
        margin: 0;
    }
}

.MrkSidebarSearch{
    display: flex;
    background-color: #25252D;
    height: 45.7px;
    border-radius: 8px;
    border-bottom: 1.5px solid rgb(14, 16, 20);

    ;
    width: 100%;
    cursor: text;
    div{
        box-sizing: border-box;
        padding: 10px;
        color: rgb(169, 188, 204);
    }
    input{
        border: none;
        font-size: 15px;
        background: none;
        color: white;
    }

    input:focus{
        outline: none;
    }

}
.MrkSidebarSearch:hover{
background-color: #2d2d35;

}
.navIcon{
    color: #313149;        
}

.stepIcon{
    color:rgba(255, 255, 255, 0.63);
}
.displayoNo{
    display: none;
}


/* ------------------------ SMARTPHONE VERSION --------------------------------*/


@media screen and (max-width: 1100px) {

        
    .MrkInfoCtn-2{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #171618;
        padding-top: 30px;
    }
    
    .MrkInfoPanel-2{
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        border: solid 1.5px rgba(230, 230, 230, 0.287);
        div{
            flex-direction: column;
        }
        
        .onlyYou{
            border-right:  solid 1.5px rgba(230, 230, 230, 0.287);
        }
        span{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 25px 20px 25px;
            border-bottom:  solid 1.5px rgba(230, 230, 230, 0.287);
            border-right: none;
        }
    
        h2{
            font-size: 5vw;
        }
        p{
            margin: 2px 0 0 0;
            font-size: 3vw;
        }
    }

    .MrkContentItems{
        margin-bottom: 320px;
    }

    .MrkContentItemsHead{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        
        span{
            width: 100%;
            display: flex;
            background-color: #25252D; 
            height: 45.7px;
            border-radius: 7px;
            margin: 5px 0;
        }
        span:hover{
            background-color: #2d2d35;
    
        }
        .format-1{
            cursor: text;
            
            div{
                box-sizing: border-box;
                padding: 10px;
                color: rgb(169, 188, 204);
            }
            input{
                border: none;
                font-size: 15px;
                background: none;
                color: white;
                width: 100%;
    
            }
            input:focus{
                outline: none;
            }
        }
     
        .format-2{
            box-sizing: border-box;
            padding: 0 5px 0 15px;
            cursor: pointer;
            margin-right: 10px;
            width: 80%;
            input{
                width: 80%;
                border: none;
                font-size: 15px;
                background: none;
                cursor: pointer;
    
            }
            input:focus{
                outline: none;
            }
            div{
                box-sizing: border-box;
                padding: 10px 0px;
                color: rgb(169, 188, 204);
            }
        }
        .format-2:active{
            background-color: #2d2d35;
        }
    }
    .contentFetched{
        border-bottom: 3px solid #946233
    }
    
    .MrkContentItemsSubHead-1{
        width: 100%;
    }
    .MrkContentItemsSubHead-2{
        width: 100%;
        justify-content: space-between;

    }
    .MrkContentItemsHead-reload{
        display: none;
    }
    
    .MrkContentItemsHead-format{
        display: flex;
        cursor: pointer;
        div{
            box-sizing: border-box;
            border: solid 1.5px rgba(230, 230, 230, 0.287);
            color: rgba(255, 255, 255, 0.76);
            width: 50px;
            height: 50px;
            padding: 12px;
        }
    }
    
    .formatActive{
        background-color: rgba(255, 255, 255, 0.096);
    }
    .Tippytoolbox{
        background-color: #2d2d35;
        display: flex;
        flex-direction: column;
        width: 200px;
        color: rgba(255, 255, 255, 0.877);
        border-radius: 8px;
        span{
            padding: 15px 0 15px 15px;
            box-sizing: border-box;
            border-bottom: 1.5px solid rgb(14, 16, 20);
    
        }
        span:hover{
            background-color: #25252D;
        }
    }   
    .MrkContentItemsCnt{
        display: flex;
        justify-content: center;
    }

    .Items{
        width: 100%;
    }

    .MrkContentSalesHead{
        display: none;
    }
    .MrkSidebar{
        display: none;
    }

}



@media screen and (max-width: 800px) {

    .Market{
        margin-bottom: 550px;
    }
}