.terms_conditions{
    background-color: #0f1013;
    box-shadow: 0px 10px 10px #0b0d0f;
}


.terms_content{
    margin:0 15vw 220px 15vw;
    padding-bottom: 30px;
    text-align:justify;
    h1{
        padding-top: 150px;
        font-family: 'anton';
        font-weight:500;
        letter-spacing: 5px;
    }
    p{
        font-family: 'space_grotesk';
        margin-top: 30px;
    }
}

@media screen and (max-width: 800px) {

    .terms_content{
        margin:0 15vw 250px 15vw;
    }
}
