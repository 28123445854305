.Tokenomics{
    background-color: #03050f;
    box-shadow: 0px 10px 10px #0b0d0f;
    margin-bottom: 220px;
    min-height: 100vh;
}


.Tokenomics_content{
    text-align:justify;
    margin-bottom:220px;

    h1{
        padding-top: 150px;
        font-family: 'anton';
        font-weight:500;
        letter-spacing: 5px;
    }
    p{
        font-family: 'space_grotesk';
        margin-top: 30px;
    }
}

.Tokenomics_subcontent{
        margin:0 15vw;
        box-sizing: border-box;
        padding: 20px 0 50px 0;
}

.Tokenomics_subcontent_mint{
    margin:0 15vw;
    box-sizing: border-box;
    padding: 20px 0 0px 0;
}




.Tokenomics_img{
    display: flex;
    justify-content: space-between;
    Img{
        width: 45%
    }
}

.Tokenomics_kalao{
        width: 300px
}

.Tokenomics_steampunk{
    width: 200px
}

.Tokenomics_marketImg{
    width: 70%;
}

@media screen and (max-width: 1500px) {

    .Tokenomics{
        height: 100%;
    }
}

@media screen and (max-width: 1100px) {

    .Tokenomics{
        margin-bottom: 230px;
    }
    h1, h3{
        margin: 0 10vw;
    }
    .Tokenomics_subcontent{
        margin: 0 10vw;
    }
    .Tokenomics_img{
        flex-direction: column; 
        Img{
            width: 100%
        }
    }
    .Tokenomics_marketImg{
        width: 100%;
    }
    
}

@media screen and (max-width: 800px) {

    .Tokenomics{
        margin-bottom: 250px;
    }
}